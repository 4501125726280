body {
    font-family: 'Nunito Sans', sans-serif !important;
}

* {
    box-sizing: border-box;
}

p, h1, h2, h3, h4, h5, h6 {
    font-family: 'Nunito Sans', sans-serif !important;
}

p {
    font-size: 1.1rem !important;
}

.ui {
    font-family: 'Nunito Sans', sans-serif !important;
}

.tabular .item:hover {
    color: #87ab36 !important;
}

a {
    color: #87ab36 !important;
}